import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import styled, { css } from "styled-components";

const RIVE_FILE_PATH = "/beam_hero_animation.riv";
const STATE_MACHINE_NAME = "State Machine 1";

const RiveWrapper = () => {
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [animations, setAnimations] = useState<{ name: string; stateMachineName: string }[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const riveContainerRef = useRef<HTMLDivElement>(null);

  const { rive, RiveComponent } = useRive({
    src: RIVE_FILE_PATH,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    onLoad: () => {
      rive?.resizeDrawingSurfaceToCanvas();
      setTimeout(() => setIsLoaded(true), 100);
    },
  });

  const resizeRiveCanvas = () => {
    if (!rive || !riveContainerRef.current) return;
    const container = riveContainerRef.current;

    const canvas = container.querySelector("canvas");
    if (!canvas) return;

    const pixelRatio = window.devicePixelRatio || 1;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    canvas.style.width = `${containerWidth}px`;
    canvas.style.height = `${containerHeight}px`;
    canvas.width = Math.floor(containerWidth * pixelRatio);
    canvas.height = Math.floor(containerHeight * pixelRatio);

    // Scale the drawing context
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.scale(pixelRatio, pixelRatio);
    }

    // Tell Rive to resize
    rive.resizeDrawingSurfaceToCanvas();
    rive.startRendering();
  };

  useEffect(() => {
    if (rive) {
      const availableAnimations = [
        { name: "TAB-1", stateMachineName: STATE_MACHINE_NAME },
        { name: "TAB-2", stateMachineName: STATE_MACHINE_NAME },
        { name: "TAB-3", stateMachineName: STATE_MACHINE_NAME },
      ];
      setAnimations(availableAnimations);
    }
  }, [rive]);

  // Play a specific animation
  const playAnimation = (index: number) => {
    if (!rive || index >= animations.length) return;
    try {
      rive.stop();
      const animation = animations[index];
      rive.play(animation.name);
      setCurrentAnimation(index);
    } catch (error) {
      console.error("Error playing animation:", error);
    }
  };

  const handleAnimationClick = () => {
    if (animations.length === 0) return;
    const nextIndex = (currentAnimation + 1) % animations.length;
    playAnimation(nextIndex);
  };

  return (
    <RiveContainer
      ref={riveContainerRef}
      onClick={handleAnimationClick}
      onTransitionEnd={resizeRiveCanvas}
      style={{ cursor: "pointer" }}
      $isLoaded={isLoaded}
    >
      <RiveComponent />
    </RiveContainer>
  );
};

export default RiveWrapper;

const RiveContainer = styled.div<{ $isLoaded: boolean }>`
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.8s ease-out, transform 0.9s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 24px;
  ${props =>
    props.$isLoaded &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`;
