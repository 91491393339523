import styled, { css } from "styled-components";
import Grid from "assets/homepage/landing/Grid2.svg";
import { typography } from "screens/Homepage/components/typography";
import { BlueHighlightedCSS, LinkMarketingButton } from "screens/Homepage/styles";
import { docsLink, dashboardLink } from "constants/Links";
import { ReactComponent as YCombinatorLogo } from "assets/y-combinator.svg";
import { useState, useEffect } from "react";
import RiveWrapper from "./RiveAnimation";

const Hero: React.FC = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const [animateContent, setAnimateContent] = useState(false);

  useEffect(() => {
    setAnimateContent(true);
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <MainInfo $animate={animateContent}>
          <Badge onClick={() => window.open("https://calendly.com/elimernit/beam-concierge-migration")}>
            <BadgeText>
              ✨ Concierge Migration Service: Let us migrate your models to Beam, for free
            </BadgeText>
          </Badge>
          <TitleWrapper>
            <HighlightedTitle $text="AI Infrastructure">AI Infrastructure</HighlightedTitle>
            <Title>for Developers</Title>
          </TitleWrapper>
          <SubTitle>
            Run custom ML models at scale with zero complexity. <br></br>One line of Python, global GPUs, full
            control.
          </SubTitle>
          <ButtonGroup>
            <LinkMarketingButton href={dashboardLink} size="lg" icon="far-angle-right">
              Get Started
            </LinkMarketingButton>
            <LinkMarketingButton
              href={docsLink}
              newWindow
              buttonTheme="secondary"
              variant="outline"
              size="lg"
              icon="far-book-blank"
            >
              Documentation
            </LinkMarketingButton>
          </ButtonGroup>
          <BadgeContainer>
            <Text>Backed by</Text>
            <YCombinatorLogo height="40" />
          </BadgeContainer>
        </MainInfo>
        <RiveContainer>{showAnimation && <RiveWrapper />}</RiveContainer>
      </ContentWrapper>
    </Container>
  );
};

export default Hero;

const ContentWrapper = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 64px;
      width: 100%;
      max-width: 1400px;
      @media (max-width: ${theme.breakpoints.lg}px) {
        flex-direction: column;
        gap: 48px;
      }

      @media (max-width: ${theme.breakpoints.md}px) {
        gap: 40px;
      }
    `;
  }}
`;

const RiveContainer = styled.div`
  ${({ theme }) => {
    return css`
      width: 100%;
      height: 520px;
      border-radius: 24px;
      display: flex;
      margin-right: -48px;
      background-color: ${theme.colors.gray1};
      border: 1px solid ${theme.colors.gray4};

      @media (max-width: ${theme.breakpoints.xl}px) {
        width: 100%;
        height: 480px;
      }

      @media (max-width: ${theme.breakpoints.lg}px) {
        display: none;
      }
    `;
  }}
`;

const BadgeContainer = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      padding: 0px;
      margin-top: 24px;
    `;
  }}
`;

const Text = styled.p`
  ${({ theme }) => {
    return css`
      color: ${theme.colors.gray10};
      ${typography.textLgLight};
    `;
  }}
`;

const Container = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      isolation: isolate;
      width: 100%;
      gap: 16px;
      background-image: url(${Grid});
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      border-bottom: 1px solid rgba(255, 255, 255, 0.04);
      padding: 64px 16px 128px 16px;

      @media (min-width: ${theme.breakpoints.sm}px) {
        padding: 64px 32px 128px 32px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        padding: 64px 48px 128px 48px;
      }

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 80px 32px 160px 32px;
      }

      @media (min-width: ${theme.breakpoints.xl}px) {
        padding: 96px 64px 160px 64px;
      }
    `;
  }}
`;

const MainInfo = styled.div<{ $animate?: boolean }>`
  ${({ theme, $animate }) => {
    return css`
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      text-align: left;
      padding: 0px;
      margin-left: 24px;
      opacity: ${$animate ? 1 : 0};
      transform: translateY(${$animate ? 0 : "40px"});
      transition: opacity 0.8s ease-out, transform 0.8s ease-out;

      @media (max-width: ${theme.breakpoints.lg}px) {
        align-items: center;
        text-align: center;
        margin-top: 0px;
        margin-left: 0px;
      }
    `;
  }}
`;

const TitleWrapper = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      @media (max-width: ${theme.breakpoints.lg}px) {
        align-items: center;
        text-align: center;
      }
    `;
  }}
`;

const Title = styled.h1`
  ${({ theme }) => {
    return css`
      all: unset;
      margin-bottom: 24px;
      color: ${theme.colors.gray12};
      text-align: center;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      max-width: 100%;
      ${typography.title56};
      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.title64};
      }
      @media (min-width: ${theme.breakpoints.md}px) {
        ${typography.title72};
      }
      @media (min-width: ${theme.breakpoints.lg}px) {
        ${typography.title64};
        text-align: left;
      }
      @media (min-width: ${theme.breakpoints.xl}px) {
        ${typography.title80};
        text-align: left;
      }
    `;
  }}
`;

const HighlightedTitle = styled(Title)<{ $text?: string }>`
  ${({ theme }) =>
    BlueHighlightedCSS(
      theme.colors.gray1,
      `0px 4px 96px rgba(83, 113, 231, 0.64), 0px 8px 192px rgba(83, 113, 231, 0.48),
          0px 0px 8px rgba(132, 157, 255, 0.48)`
    )};
`;

const SubTitle = styled.p`
  ${({ theme }) => {
    return css`
      all: unset;
      margin-bottom: 48px;
      color: ${theme.colors.gray11};
      max-width: 750px;
      ${typography.textLgLight};

      @media (min-width: ${theme.breakpoints.sm}px) {
        ${typography.textXlLight};
      }
    `;
  }}
`;

const ButtonGroup = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-gap: 16px;
      justify-items: center;
      grid-template-columns: auto auto;

      @media (max-width: ${theme.breakpoints.sm}px) {
        width: 100%;
        grid-template-columns: 1fr;
        a,
        button {
          width: 100%;
        }
      }
    `;
  }}
`;

const Badge = styled.button`
  margin-bottom: 20px;
  height: 60px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: #1b1b1d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(90deg, #5371e7, #84a4ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

const BadgeText = styled.p`
  ${({ theme }) => {
    return css`
      color: ${theme.colors.gray11};

      ${typography.textSmLight};
      font-weight: 500;
      text-align: center;
      color: white;
      margin: auto;
    `;
  }}
`;
